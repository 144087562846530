import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <nav className="flex items-center justify-between flex-wrap p-6">
    <Link to="/">
      <h1 className="text-white hover">JB</h1>
    </Link>
    <Link to="/about">
      <h3 className="text-white float-right mr-10 hover">About</h3>
    </Link>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
